import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Estatisticas() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    // Requisição para a API de estatísticas
    fetch('https://mix-gpt-server.vercel.app/api/estatisticas')
      .then(response => response.json())
      .then(data => setStats(data))
      .catch(error => console.error('Erro ao buscar estatísticas:', error));
  }, []);

  if (!stats) {
    return <p>Carregando estatísticas...</p>;
  }

  // Dados para o gráfico de barras
  const data = {
    labels: stats.procedimentos_mais_curtidos.map(item => item.titulo),
    datasets: [
      {
        label: 'Curtidas',
        data: stats.procedimentos_mais_curtidos.map(item => item.likes),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Procedimentos Mais Populares (Curtidas)',
      },
    },
  };

  return (
    <div className="estatisticas-container">
      <h1>Estatísticas do Sistema</h1>
      <div className="estatisticas-content">
        {/* Outras seções de estatísticas */}
        <div className="estatistica-item">
          <h2>Total de Procedimentos</h2>
          <p>{stats.total_procedimentos}</p>
        </div>

        {/* Gráfico de barras */}
        <div className="estatistica-item">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default Estatisticas;
