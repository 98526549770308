
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'; // Importação necessária
import Estatisticas from './components/Estatisticas'; // Importando o componente de estatísticas
import { FaEdit } from 'react-icons/fa';






function App() {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null); 
  const [showAddForm, setShowAddForm] = useState(false);
  const [showResolutionForm, setShowResolutionForm] = useState(false); 
  const [newTitle, setNewTitle] = useState('');
  const [newResolution, setNewResolution] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false); 
  const [showChat, setShowChat] = useState(false); 
  const [chatHistory, setChatHistory] = useState(() => {
    const storedChat = sessionStorage.getItem('chatHistory');
    return storedChat ? JSON.parse(storedChat) : [];
  });
  const [isTyping, setIsTyping] = useState(false); 
  const [fadeOut, setFadeOut] = useState(false); 
  const [likes, setLikes] = useState(0); 
  const [isLoggedIn, setIsLoggedIn] = useState(() => localStorage.getItem('isLoggedIn') === 'true');
  const [dislikes, setDislikes] = useState(0); 
  const [userVoted, setUserVoted] = useState(false); 
  const [votedProcedures, setVotedProcedures] = useState(() => {
    const storedVotes = localStorage.getItem('votedProcedures');
    return storedVotes ? new Set(JSON.parse(storedVotes)) : new Set();
  });

  // Estados Editar
  const [showEditForm, setShowEditForm] = useState(false);
  const handleSaveChanges = () => {
    const updatedProcedure = {
      titulo: newTitle,
      resolucao: newResolution,
    };
  
    fetch(`https://mix-gpt-server.vercel.app/api/procedimentos/${selectedProcedure.id}`, {
      method: 'PUT', // Método PUT para atualizar o procedimento
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProcedure),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao atualizar o procedimento');
        }
        return response.json();
      })
      .then(data => {
        setShowEditForm(false);
        // Atualizar o procedimento localmente para refletir as mudanças
        setSelectedProcedure({ ...selectedProcedure, ...updatedProcedure });
      })
      .catch((error) => {
        console.error('Erro ao atualizar o procedimento:', error);
      });
  };


const handleEdit = () => {
  setNewTitle(selectedProcedure.titulo);
  setNewResolution(selectedProcedure.resolucao);
  setShowEditForm(true);
};

  // Criando referências para os inputs de login e o botão de login
  const userInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);
  const navigate = useNavigate();
  const mainInputRef = useRef(null); // Referência para o campo de entrada principal
  const resolutionInputRef = useRef(null);
  const chatInputRef = useRef(null);
  const [showLogin, setShowLogin] = useState(false);
  
  // Definir as funções administrativas antes do retorno principal do componente
  const handleCreateProcedure = () => {
    setShowAddForm(true); // Define que o formulário de adição deve ser exibido
    setShowResolutionForm(true); // Define que o formulário de resolução deve ser exibido
  };

  // Função para proteger a rota de estatísticas
const ProtectedRoute = ({ isLoggedIn, children }) => {
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate('/'); // Redireciona para a página inicial caso não esteja logado
    return null;
  }

  return children;
};

const handleEditProcedure = () => {
  console.log('Editar Procedimentos');
};

const handleDeleteProcedure = () => {
  console.log('Remover Procedimentos');
};

const handleViewStatistics = () => {
  navigate('/estatisticas');
};


  // Função para focar no campo de usuário ao carregar a página de login
  useEffect(() => {
    if (showLogin) {
      userInputRef.current.focus();
    }
  }, [showLogin]);

  // Função para manusear o login ao pressionar "Enter"
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      loginButtonRef.current.click(); // Aciona o clique do botão de login
    }
  };

  const toggleLogin = () => {
    setShowLogin((prevShowLogin) => !prevShowLogin);
  };

  const handleLogin = () => {
    const username = userInputRef.current.value;
    const password = passwordInputRef.current.value;

    fetch('https://mix-gpt-server.vercel.app/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setIsLoggedIn(true);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('username', username);

          setShowLogin(false);
        } else {
          alert('Erro no login: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Erro ao tentar login:', error);
        alert('Erro ao tentar login.');
      });
  };


  //Novaatt
  useEffect(() => {
    // Limpa o sessionStorage ao carregar a página
    sessionStorage.removeItem('chatHistory');
    setChatHistory([]);
  }, []);

  // Sincronizar estado de login
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);
  

  useEffect(() => {
    const chatHistoryDiv = document.querySelector('.chat-history');
    if (chatHistoryDiv) {
      chatHistoryDiv.scrollTop = chatHistoryDiv.scrollHeight;
    }
  }, [chatHistory]); // Sempre que o histórico de chat mudar
  

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (showChat) {
        if (event.key === 'Escape') {
          setChatHistory([]); // Limpa o histórico de mensagens
          sessionStorage.removeItem('chatHistory'); // Limpa o sessionStorage
          closeChat(); // Fecha o chat
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showChat]);
  

  // Armazena o histórico no sessionStorage
  useEffect(() => {
    sessionStorage.setItem('chatHistory', JSON.stringify(chatHistory));
  }, [chatHistory]);

  const resetPage = () => {
    setQuery('');
    setSuggestions([]);
    setSelectedProcedure(null);
    setShowAddForm(false);
    setShowResolutionForm(false);
    setNewTitle('');
    setNewResolution('');
    setUserVoted(false);
    sessionStorage.removeItem('chatHistory'); // Limpa o sessionStorage
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setNewTitle(value);

    if (value.length > 2) {
      fetch(`https://mix-gpt-server.vercel.app/api/procedimentos?term=${value}`)
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data) && data.length > 0) {
            setSuggestions(data);
            setShowAddForm(false);
            const listItems = document.querySelectorAll("li");
            listItems.forEach((item) => {
              item.classList.add('fade-in');
            });
          } else {
            setSuggestions([]);
            setShowAddForm(true);
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar dados:", error);
          setSuggestions([]);
        });
    } else if (value.length === 0) {
      setSuggestions([]); 
      setShowAddForm(false); 
      setSelectedProcedure(null); 
      resetPage(); 
    }
  };

  const handleSelectProcedure = (procedure) => {
    setSelectedProcedure(procedure); 
    setSuggestions([]);
    setShowAddForm(false); 
    setShowResolutionForm(false); 
    setLikes(procedure.likes || 0); 
    setDislikes(procedure.dislikes || 0); 
    setUserVoted(votedProcedures.has(procedure.id)); 
  };

const handleAddProcedure = () => {
    setShowAddForm(true);
    setShowResolutionForm(true);
};

const handleConcludeProcedure = () => {
  const username = localStorage.getItem('username'); // Pegando o username do localStorage
  const newProcedure = {
    titulo: newTitle,
    resolucao: newResolution,
    username: username, // Incluindo o username ao criar o procedimento
  };

    fetch('https://mix-gpt-server.vercel.app/api/procedimentos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newProcedure)
    })
      .then(response => response.json())
      .then(data => {
        setShowSuccess(true); 
        setTimeout(() => {
          setShowSuccess(false);
          resetPage(); 
        }, 3000); 
      })
      .catch((error) => {
        console.error('Erro ao adicionar procedimento:', error);
      });
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter' && query.trim() !== '') {
      openChat(); 
    }
  };

  const updateLocalStorage = (newVotedProcedures) => {
    localStorage.setItem('votedProcedures', JSON.stringify([...newVotedProcedures]));
    setVotedProcedures(newVotedProcedures);
  };

  const handleLike = () => {
    if (!userVoted && selectedProcedure) {
      setLikes(likes + 1);
      setUserVoted(true);

      const newVotedProcedures = new Set([...votedProcedures, selectedProcedure.id]);
      updateLocalStorage(newVotedProcedures);

      fetch(`https://mix-gpt-server.vercel.app/api/procedimentos/${selectedProcedure.id}/like`, {
        method: 'POST'
      });
    }
  };

  const handleDislike = () => {
    if (!userVoted && selectedProcedure) {
      setDislikes(dislikes + 1);
      setUserVoted(true);

      const newVotedProcedures = new Set([...votedProcedures, selectedProcedure.id]);
      updateLocalStorage(newVotedProcedures);

      fetch(`https://mix-gpt-server.vercel.app/api/procedimentos/${selectedProcedure.id}/dislike`, {
        method: 'POST'
      });
    }
  };

  const openChat = () => {
    if (query.trim() === '') {
      return;
    }
    setFadeOut(false);
    setShowChat(true);
    setShowAddForm(false);
    setShowResolutionForm(false);
    setSelectedProcedure(null);
    setSuggestions([]);

    handleAskGPT(); 

    const chatbox = document.querySelector(".chatbox");
    if (chatbox) {
      chatbox.classList.add("fade-effect");
    }
  };

  const closeChat = () => {
    const chatbox = document.querySelector(".chatbox");
    if (chatbox) {
      chatbox.classList.add("fade-out");
    }
  
    setTimeout(() => {
      setShowChat(false);
      setFadeOut(false);
      setChatHistory([]); // Limpa o histórico de mensagens no estado
      sessionStorage.removeItem('chatHistory'); // Limpa o sessionStorage
      resetPage(); // Reseta a página      
    }, 2000); 
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (showChat) {
        if (event.key === 'Escape') {
          closeChat();
        } else if (event.key === 'Enter') {
          handleAskGPT();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showChat, query]);

  const handleAskGPT = () => {
    if (query.trim() === '') {
      return; 
    }
  
    const userMessage = query;
  
    // Adiciona a nova mensagem do usuário ao histórico
    const newHistory = [...chatHistory, { user: userMessage, response: '' }];
    
    // Atualiza o estado e o sessionStorage
    setChatHistory(newHistory);
    sessionStorage.setItem('chatHistory', JSON.stringify(newHistory));
    
    setQuery(''); 
    setIsTyping(true);
  
    // Prepara o histórico para envio
    const messages = newHistory.map((message) => ({
      role: 'user',
      content: message.user
    }));
  
    fetch('https://mix-gpt-server.vercel.app/api/gpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ history: messages })
    })
      .then(response => response.json())
      .then(data => {
        setIsTyping(false);
        if (data.message) {
          // Atualiza o histórico com a resposta da IA
          setChatHistory((prevHistory) => {
            const updatedHistory = [...prevHistory];
            updatedHistory[updatedHistory.length - 1].response = data.message;
            sessionStorage.setItem('chatHistory', JSON.stringify(updatedHistory));
            return updatedHistory;
          });
        } else {
          console.error('Resposta inválida da API GPT:', data);
        }
      })
      .catch((error) => {
        console.error('Erro ao consultar GPT:', error);
        setIsTyping(false); // Certifique-se de que o estado "isTyping" seja atualizado
      });
  };
  
useEffect(() => {
  if (showChat && chatInputRef.current) {
    chatInputRef.current.focus(); 
  }
}, [showChat]);

const handleCloseProcedure = () => {
  resetPage(); 
};

// Definir a função de logout antes do retorno principal do componente
const handleLogout = () => {
  setIsLoggedIn(false);
  localStorage.removeItem('isLoggedIn'); // Remove o status de login do localStorage
};




function Estatisticas() {
  const [stats, setStats] = useState(null); // Estado para armazenar as estatísticas

  useEffect(() => {
    // Fazer a requisição para a rota de estatísticas
    fetch('https://mix-gpt-server.vercel.app/api/estatisticas')
      .then(response => response.json())
      .then(data => setStats(data))
      .catch(error => console.error('Erro ao buscar estatísticas:', error));
  }, []);

  // Verificar se os dados já foram carregados
  if (!stats) {
    return <p>Carregando estatísticas...</p>;
  }

  return (
    <div className="estatisticas-page">
      <h1>Estatísticas do Sistema</h1>
      <div className="estatisticas-content">
        <div className="estatistica-item">
          <h2>Total de Procedimentos</h2>
          <p>{stats.total_procedimentos}</p>
        </div>
        <div className="estatistica-item">
          <h2>Procedimentos Mais Populares</h2>
          <ul>
            {stats.procedimentos_mais_curtidos.map((item, index) => (
              <li key={index}>{item.titulo} - {item.likes} curtidas</li>
            ))}
          </ul>
        </div>
        <div className="estatistica-item">
          <h2>Procedimentos com Mais Dislikes</h2>
          <ul>
            {stats.procedimentos_menos_curtidos.map((item, index) => (
              <li key={index}>{item.titulo} - {item.dislikes} dislikes</li>
            ))}
          </ul>
        </div>
        <div className="estatistica-item">
          <h2>Procedimentos Mais Recentes</h2>
          <ul>
            {stats.procedimentos_mais_recentes.map((item, index) => (
              <li key={index}>{item.titulo} - Criado em {item.data_criacao}</li>
            ))}
          </ul>
        </div>
        <div className="estatistica-item">
          <h2>Ranking de Criação de Procedimentos por Usuário</h2>
          <ul>
            {stats.ranking_usuarios.map((item, index) => (
              <li key={index}>{item.username} - {item.total} procedimentos</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}




// Linha antes da tag principal Div Principal
return (
  <div className="container fade-effect">
    <div
      className="login-text"
      style={{ position: 'absolute', top: '10px', left: '10px', color: 'gray', cursor: 'pointer' }}
      onClick={isLoggedIn ? handleLogout : toggleLogin}
    >
      {isLoggedIn ? 'Logado - Fazer Logoff' : 'Login'}
    </div>

    {showLogin && (
      <div className="login-form" style={{ position: 'absolute', top: '50px', left: '10px', backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '20px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '12px', maxWidth: '300px' }}>
        <h2 style={{ fontSize: '1.5em', marginBottom: '20px', color: '#333' }}>Login</h2>
        <input 
          type="text" 
          placeholder="Usuário" 
          ref={userInputRef}
          style={{ display: 'block', marginBottom: '15px', padding: '10px', width: '100%', fontSize: '1em', borderRadius: '8px', border: '1px solid #ccc' }}
        />
        <input 
          type="password" 
          placeholder="Senha" 
          ref={passwordInputRef}
          style={{ display: 'block', marginBottom: '15px', padding: '10px', width: '100%', fontSize: '1em', borderRadius: '8px', border: '1px solid #ccc' }}
        />
        <button 
          ref={loginButtonRef}
          onClick={handleLogin}
          style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '8px' }}
        >
          Entrar
        </button>
      </div>
    )}

    <img src="/img/mix.png" alt="Logo Rádio Mix" className="logo" onClick={resetPage} />

    <Routes>
  <Route
    path="/estatisticas"
    element={
      <ProtectedRoute isLoggedIn={isLoggedIn}>
        <Estatisticas />
      </ProtectedRoute>
    }
  />
</Routes>

    {!showChat && (
      <>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleEnterKey} 
          placeholder="Digite o problema..."
          autoComplete="off" 
          id="newTitle" 
        />
        <ul>
          {suggestions.map((item, index) => (
            <li key={index} className="fade-in" onClick={() => handleSelectProcedure(item)}>
              {item.titulo}
            </li>
          ))}
        </ul>

        {selectedProcedure && (
  <div className="details fade-effect">
    <h2>Detalhes do Procedimento</h2>
    {!showEditForm && isLoggedIn && (
      <div className="edit-button-container">
        <button 
          onClick={handleEdit} 
          className="edit-button"
        >
          <FaEdit /> Editar
        </button>
      </div>
    )}
    {!showEditForm ? (
      <>
        <p><strong>Título:</strong> {selectedProcedure.titulo}</p>
        <p><strong>Resolução:</strong></p>
        <p className="resolucao">{selectedProcedure.resolucao.split('\n').map((line, i) => (
          <span key={i}>{line}<br /></span>
        ))}</p>
      </>
    ) : (
      <div className="edit-form fade-effect">
        <h2>Editar Procedimento</h2>
        <input
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          placeholder="Título"
        />
        <textarea
          value={newResolution}
          onChange={(e) => setNewResolution(e.target.value)}
          placeholder="Resolução"
          rows="6"
          style={{ width: '100%' }}
        />
        <button onClick={handleSaveChanges}>Salvar Alterações</button>
      </div>
    )}

    <div className="like-dislike">
      <button className="like-button" onClick={handleLike} disabled={userVoted}>👍 {likes}</button>
      <button className="dislike-button" onClick={handleDislike} disabled={userVoted}>👎 {dislikes}</button>
    </div>

    <button className="close-button" onClick={handleCloseProcedure}>Fechar</button>
  </div>
)}

        {showResolutionForm && (
          <div className="resolution-form fade-effect">
            <div className="resolution-content">
              <h2>Adicionar Resolução</h2>
              <p><strong>Título:</strong> {newTitle}</p>
              <textarea
                ref={resolutionInputRef} 
                value={newResolution}
                onChange={(e) => setNewResolution(e.target.value)}
                placeholder="Escreva a resolução aqui..."
                rows="6"
                style={{ width: '100%' }}
              />
              <button onClick={handleConcludeProcedure}>Concluir Procedimento</button>
            </div>
          </div>
        )}
        <button id="chatButton" onClick={openChat} className="fade-effect">Pergunte ao GPTMix</button>
      </>
    )}

    {isLoggedIn && showAddForm && !showResolutionForm && (
      <div className="fade-effect">
        <h2>Adicionar novo procedimento</h2>
        <input
          id="newTitle"
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          placeholder="Título"
        />
        <button id="addButton" onClick={handleAddProcedure}>Adicionar Procedimento</button>
      </div>
    )}

    {isLoggedIn && (
      <div className="admin-menu" style={{ position: 'absolute', left: '10px', top: '100px', backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '20px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '12px', maxWidth: '200px' }}>
        <h3 style={{ fontSize: '1.2em', marginBottom: '15px', color: '#333' }}>Administração</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <button className="button-general" onClick={handleAddProcedure} style={{ backgroundColor: '#fff', color: '#4CAF50', boxShadow: 'none', outline: 'none' }}>Criar</button>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <button className="button-general" onClick={handleViewStatistics} style={{ backgroundColor: '#fff', color: '#4CAF50', boxShadow: 'none', outline: 'none' }}>Estatísticas</button>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <button className="button-general" onClick={handleLogout} style={{ backgroundColor: '#fff', color: '#4CAF50', boxShadow: 'none', outline: 'none' }}>Logoff</button>
          </li>
        </ul>
      </div>
    )}

    {showChat && (
      <div className={`chatbox fade-effect ${fadeOut ? 'fade-out' : ''}`}>
        <div className="chatbox-header">
          <button className="close-chat" onClick={closeChat}>X</button>
        </div>
        <h2>Chat com GPTMix</h2>
        <div className="chat-history">
          {chatHistory.map((message, index) => (
            <div key={index} className="chat-message fade-effect">
              <p><strong>Você:</strong> {message.user}</p>
              <p><strong>GPTMix:</strong> {message.response}</p>
            </div>
          ))}
          {isTyping && (
            <div className="chat-message typing-animation">
              <p><strong>GPTMix:</strong> <span className="dots">•••</span></p>
            </div>
          )}
        </div>
        <input
          ref={chatInputRef}
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Digite sua pergunta..."
        />
        <button onClick={handleAskGPT}>Enviar</button>
      </div>
    )}

<div className="player-container">
        <audio controls>
          <source src="https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOPAULO.mp3" type="audio/mpeg" />
          Seu navegador não suporta o elemento de áudio.
        </audio>
      </div>

      {!showChat && !selectedProcedure && !showResolutionForm && (
        <footer className="footer fade-effect">
          <p>Desenvolvido por Fabio Carvalho</p>
        </footer>
      )}
    </div>
  );
}

export default App;